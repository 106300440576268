import React, {
  createElement,
  CSSProperties,
  FunctionComponent,
  ReactNode,
} from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkCold } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { slugify } from 'src/slug';
import { Copy } from '../../../component/copy';
import { ScreenSize, useScreenSize } from '../../../component/display';
import { Variant } from './types';
import { TypographyAnchor } from './TypographyAnchor';
import { TypographyWrapAttach } from './TypographyWrapAttach';
import classes from './Typography.module.css';

const classNameVariant: { [Property in Variant]: string } = {
  titleXl: classes.titleXl,
  titleLg: classes.titleLg,
  title: classes.title,
  captionLg: classes.captionLg,
  caption: classes.caption,
  captionSm: classes.captionSm,
  body: classes.body,
  code: classes.code,
};

const nodeTypeVariant: { [Property in Variant]: string | null } = {
  titleXl: 'h1',
  titleLg: 'h2',
  title: 'h3',
  captionLg: 'h4',
  caption: 'h5',
  captionSm: 'h6',
  body: 'p',
  code: null,
};

/*
 * Wrap the TypographyAnchor with Copy so that a user can copy and go to the
 * anchor when clicked.
 */
const CopyTypographyAnchor: FunctionComponent<{
  anchor: string | { content: string; icon: ReactNode };
  variant: Variant;
}> = ({ anchor, variant }) => {
  const isSm = useScreenSize(ScreenSize.Small);

  const anchorContent = typeof anchor === 'string' ? anchor : anchor.content;
  const anchorIcon = typeof anchor === 'string' ? null : anchor.icon;

  const slug = slugify(anchorContent);

  // Create href for anchor.
  const url = new URL(window.location.href);
  url.hash = `#${slug}`;

  return (
    <Copy
      direction={isSm ? 'right' : 'left'}
      content={`${url.origin}${url.pathname}${url.hash}`}
      textCopied={
        <svg
          stroke="var(--theme-color-2)"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
            fill="var(--theme-color-background)"
          ></path>
          <rect x="9" y="3" width="6" height="4" rx="2"></rect>
          <path
            d="M9 14l2 2l4 -4"
            style={{ stroke: 'var(--theme-color-success)' }}
          ></path>
        </svg>
      }
      style={{
        ...(!isSm
          ? {
              position: 'absolute',
              height: '100%',
              top: 0,
              left:
                // Calculate anchor placement based on container font sizes.
                variant === 'titleXl'
                  ? '-0.9375em'
                  : variant === 'titleLg'
                  ? '-1.2em'
                  : variant === 'title'
                  ? '-1.25em'
                  : '-30px',
            }
          : {
              position: 'relative',
              marginLeft:
                // Calculate anchor placement based on container font sizes.
                variant === 'titleXl'
                  ? '0.208em'
                  : variant === 'titleLg'
                  ? '0.313em'
                  : variant === 'title'
                  ? '0.417em'
                  : '8px',
            }),
      }}
    >
      <TypographyAnchor
        anchor={anchorContent}
        variant={variant}
        children={anchorIcon}
      />
    </Copy>
  );
};

export const Typography: FunctionComponent<{
  variant: Variant;
  className?: string;
  style?: CSSProperties;
  anchor?: string | { content: string; icon: ReactNode };
  noWrap?: boolean;
  id?: string;
  children: React.ReactNode;
  // variant: 'code'
  language?: string;
  inline?: boolean;
  lineNumbers?: boolean;
}> = ({
  variant,
  className,
  style,
  anchor,
  noWrap,
  id,
  children,
  // variant: 'code'
  language,
  inline,
  lineNumbers,
}) => {
  const isSm = useScreenSize(ScreenSize.Small);

  if (
    variant !== 'code' ||
    (typeof children !== 'string' && !(children instanceof Array))
  ) {
    // Which nodeType belongs to this variant?
    const nodeType = nodeTypeVariant[variant];
    if (!nodeType) {
      throw new Error(`Variant '${variant}' does not have a node type.`);
    }

    return createElement(nodeType, {
      children: (
        <span
          id={id}
          style={{
            position: 'relative',
            display: !isSm ? 'inherit' : !anchor ? 'inherit' : 'inline',
            // Inherit everything else.
            whiteSpace: 'inherit',
            height: 'inherit',
            width: 'inherit',
            alignItems: 'inherit',
            justifyContent: 'inherit',
          }}
        >
          {!anchor ? (
            <>{children}</>
          ) : !isSm ? (
            <>
              {children}
              {anchor ? (
                <CopyTypographyAnchor variant={variant} anchor={anchor} />
              ) : null}
            </>
          ) : (
            <TypographyWrapAttach>
              {children}
              <CopyTypographyAnchor variant={variant} anchor={anchor} />
            </TypographyWrapAttach>
          )}
        </span>
      ),
      className: `${classes.typography} ${classNameVariant[variant]} ${
        className ? className : ''
      }`,
      style: { whiteSpace: !noWrap ? 'unset' : 'nowrap', ...style },
    });
  } else {
    // These inline styles are used because syntax highlighter doesn't use class names for some styles.
    const styleCode = {
      margin: 0,
      fontFamily: 'var(--theme-font-family-3)',
      fontSize: '1em',
      borderWidth: '0.0625em',
      borderStyle: 'solid',
      borderColor:
        'hsl(var(--theme-color-text-hs), calc(var(--theme-color-text-l) + 90%))',
      borderRadius: '0.25em',
    };
    if (!inline) {
      return (
        <div id={id} style={{ marginBottom: '1em' }}>
          <SyntaxHighlighter
            className={`${classes.typography} ${classNameVariant[variant]} ${
              className ? className : ''
            }`}
            customStyle={styleCode}
            children={children}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ ...coldarkCold }}
            language={language}
            {...(lineNumbers
              ? {
                  showLineNumbers: true,
                  // Wrap lines is off because it wraps wrong on mobile.
                  wrapLines: false,
                  wrapLongLines: false,
                  lineNumberContainerStyle: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  },
                  lineNumberStyle: {
                    gridColumn: '1',
                    minWidth: 'unset',
                    width: '1.5em',
                    textAlign: 'right',
                    paddingRight: '0.25em',
                  },
                }
              : {})}
            PreTag="div"
          />
        </div>
      );
    } else {
      const styleCodeInline = {
        ...styleCode,
        fontSize: 'inherit',
        fontWeight: 400,
        padding: '0.01em 0.3em 0.01em',
        display: 'inline',
      };
      return (
        <span id={id}>
          <SyntaxHighlighter
            useInlineStyles
            customStyle={styleCodeInline}
            codeTagProps={{
              style: {
                whiteSpace: 'pre',
                background: 'none',
                textAlign: 'left',
                wordSpacing: 'normal',
                wordBreak: 'normal',
                overflowWrap: 'normal',
                lineHeight: '1.5',
                tabSize: 4,
                hyphens: 'none',
                ...styleCode,
              },
            }}
            className={`${classes.typography} ${classNameVariant[variant]} ${
              className ? className : ''
            }`}
            children={children}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{ ...coldarkCold }}
            language={language}
            PreTag="div"
          />
        </span>
      );
    }
  }
};
